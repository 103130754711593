:root {
  --size-wheel: 25rem;
} 
@media only screen and (max-width: 600px) { 
  .title {
    font-size: 3vw !important;       
  }
 .wheel {
  width: 350px !important;
  height: 350px !important;
}
span::before{
   left:155px!important;
  bottom: 219px !important;
} 
.modal-content h2 {

  font-size: 2.5vw !important;
}
}
html {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  background-image: url('../../img/Mau1_Desktop.png');
  font-family: roboto;
  color: white; 
  background-size: cover;
}
@keyframes rainbow {
  0% { background-color: red; }
  14% { background-color: orange; }
  28% { background-color: yellow; }
  42% { background-color: green; }
  57% { background-color: blue; }
  71% { background-color: indigo; }
  85% { background-color: violet; }
  100% { background-color: red; }
}

.btn--wheel {
  padding: 12px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  animation: rainbow 2s infinite; /* Thời gian hiệu ứng là 2 giây và lặp lại vô hạn */
  transition: transform 0.2s ease;
}

.btn--wheel:hover {
  transform: scale(1.1); /* Tăng kích thước khi hover */
} 

#root{
  width: 100%;
} 
.app{
  width: 100%;
}
.adz{
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
} 

body {
  height: 100%;
  display: flex;
  align-items: center; 
  width: 100%;
  overflow-y: scroll;
} 
.nutdangky{
  display: none;
}
section {
  width: 100%;
  height: 100vh;
}
.form{
  display: flex;
    flex-direction: column;
    gap: 27px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.msg {
  min-height: 6rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0.5rem;
  text-transform: capitalize;
}

.wheel {
  width: 500px;
  height: 500px;
} 

.title {
  font-size: 1.5vw;                /* Kích thước chữ */
  font-weight: bold;             /* Chữ đậm */
  text-align: center;            /* Căn giữa */
  animation: colorfulBlinking 1.5s infinite; /* Hiệu ứng nhấp nháy nhiều màu */
}

@keyframes colorfulBlinking {
  0% {
    color: #FFFFFF; /* Màu trắng */
  }
  25% {
    color: #FFD700; /* Màu vàng nhạt */
  }
  50% {
    color: #FFA500; /* Màu cam nhạt */
  }
  75% {
    color: #ADD8E6; /* Màu xanh lam nhạt */
  }
  100% {
    color: #FFB6C1; /* Màu hồng nhạt */
  }
}

ul {
  position: relative;
  padding: 0;
  margin: 2rem 0;
  width: var(--size-wheel);
  height: var(--size-wheel);
  border: 2px solid rgb(244, 107, 19);
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  transition: cubic-bezier(0.075, 0.8, 0.2, 1) 7s;
}

.group-wheel {
  display: flex;
  flex-direction: row;
}

span {
  display: inline-block;
  position: relative;
  padding: 0.5rem;
}

span::before {
  content: "";
  position: absolute;
  bottom: 290px;
  left: 233px;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid #ff5733;
  z-index: 2;
  transform: rotate(90deg);
  /* transform: translateX(-50%); */
  /* animation: arrow ease-out 0.6s infinite; */
}

@keyframes arrow {
  0% {
    top: -2rem;
  }
  80% {
    top: 0;
  }
  100% {
    top: -1.5rem;
  }
}

/* span::after {
    content: 'Quay!';
    align-items:center;
    display: flex;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: 220px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ff5733;
    border-radius: 50%;
} */

li {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ff5733;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  display: block;
  text-align: center;
  padding-top: 1.7rem;
  font-weight: 600;
  color: black;
}

.text > b {
  display: inline-block;
  word-break: break-word;
  max-width: 20%;
}

.text-1 {
  background-color: #61fff5;
}

.text-2 {
  background-color: #ffcd6f;
}

.text-3 {
  background-color: #b3a3ff;
}

.text-4 {
  background-color: #ffc3b6;
}

.text-5 {
  background-color: #b2ffc0;
}

.text-6 {
  background-color: #b2c0ff;
}

.text-7 {
  background-color: #ff8bcc;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

input {
  padding: 12px 15px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 250px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

button {
  text-align: center;
  border-radius: 4rem;
  padding: 12px 20px;
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.gift-code {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.btn--confirm {
  margin-right: 1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../../img/vqnohu.png'); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-size: contain;
}

/* Nội dung modal */
.modal-content {
  background: #f2f8ff80;;
  padding: 20px;
  border-radius: 17px;
  text-align: center;
  color: black;
}

.modal-content h2 {
  margin-bottom: 20px; 
  color: crimson;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #c7080a;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #7a6f78;
}
.input-form{
  width: 65%;
  margin: 0 auto;
} 
.button-form{
  width: 65%;
  margin: 0 auto;
} 
.titl{ 
  width: 100%;
  margin: auto;
}